import { ImageListItem } from '@mui/material';
import * as React from 'react';

export interface TileProps {
    name: string
    previewUri: string
}

export function NftTile(props: TileProps) {
    return (
        (<ImageListItem key={props.previewUri}>
            <img
                src={`${props.previewUri}?w=450&h=450&fit=crop&auto=format`}
                srcSet={`${props.previewUri}?w=450&h=450&fit=crop&auto=format&dpr=2 2x`}
                alt={props.name}
                loading="lazy"
            />
        </ImageListItem>)
    );
}