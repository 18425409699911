import React from 'react';
import './App.css';
import { RecoilRoot } from 'recoil';
import { Main, TopBar, NftGallery } from './components';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App({ signOut }: any): JSX.Element {
  const theme = createTheme({});

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <TopBar signOut={signOut} />
          <Routes>
            <Route path="/gallery" element={<NftGallery />} />
            <Route path="/" element={<Main />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  );
}
export default withAuthenticator(App, { socialProviders: ['google'] });
