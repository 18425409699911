import { Button } from '@mui/material';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { getProvider } from '../utils/ethereum';
import { addressState } from '../atoms';
import { getChallenge, addWallet, getWallets } from '../utils';

export function ConnectWallet() {
  const setAddress = useRecoilState(addressState)[1];
  const connect = async () => {
    await getWallets();
    const provider = getProvider();
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    setAddress(address);
    const challenge = await getChallenge(address);
    const signedChallenge = await signer.signMessage(challenge);
    addWallet(address, signedChallenge);
  }

  return (
    <div>
      <Button color="inherit" onClick={connect}>Connect Wallet</Button>
    </div>
  );
}