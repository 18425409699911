import { API } from 'aws-amplify';


export async function getChallenge(addr: string): Promise<string> {
    const res = await API.get('challenge', `/challenge/${addr}`, {});
    return res.message;
}

export async function addWallet(addr: string, signedChallenge: string): Promise<void> {
    await API.post(
        'wallet',
        '/wallet',
        { 
            body: { 
                addr: addr,
                signedChallenge: signedChallenge
            }
        }
    );
}

export async function getWallets(): Promise<void> {
    const res = await API.get('wallet', '/wallet', {});
    console.log(res);
}