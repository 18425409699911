import { ImageList, ImageListItem } from '@mui/material';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { nftsState } from '../../atoms';
import { RetrieveNfts } from '../Retrieve';
import { NftTile } from './NftTile';

export function NftGallery() {
    const nfts = useRecoilValue(nftsState);
    const images = nfts.map(item => (
        (<NftTile name={item.name} previewUri={item.previewUri || ''}></NftTile>)
    ));

    return (
        <div>
            <RetrieveNfts />
            <ImageList sx={{ width: 1500, height: 1500 }} cols={3} rowHeight={500}>
                {images}
            </ImageList>
        </div>
    );
}