/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d3ad67ed-95d0-494f-9778-b5b531574977",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OSSIW7Eh1",
    "aws_user_pools_web_client_id": "7ohke3ka80a77dqtta91ja4q2m",
    "oauth": {
        "domain": "nftgalleryb350e53f-b350e53f-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://cryptocurves.io/",
        "redirectSignOut": "https://cryptocurves.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "challenge-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "wallet-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "nftcache-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "challenge",
            "endpoint": "https://hs6p3gdyc4.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "wallet",
            "endpoint": "https://dqucws9f8b.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "nft",
            "endpoint": "https://p7ihiv0daa.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
