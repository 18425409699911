import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { addressState } from '../atoms';
import { NftGallery } from './gallery';
import { ConnectWallet } from './ConnectWallet';

export function Main() {
  const addr = useRecoilValue(addressState);

  return addr ? (
    <div>
      <NftGallery />
    </div>
  ) : (
    <div>
      <ConnectWallet />
    </div>
  );
}